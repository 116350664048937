import { template as template_1f0a2e219d1241529c6f630facc90d32 } from "@ember/template-compiler";
const FKControlMenuContainer = template_1f0a2e219d1241529c6f630facc90d32(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
